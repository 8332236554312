<template>
  <FormModal title="Type aanvraag">
    <template #body>
      <ServiceFormStep :progress-value="progressValue" />
    </template>
  </FormModal>
</template>

<script>
import FormModal from 'chimera/all/themes/blueflow/components/layout/FormModal'
import AbstractFormPage from 'chimera/all/components/page/AbstractFormPage'
import ServiceFormStep from '~/components/form/steps/service/ServiceFormStep'

export default {
  components: {
    ServiceFormStep,
    FormModal,
  },

  extends: AbstractFormPage,

  layout: 'form',

  /**
   * @returns {object}
   */
  data() {
    return {
      headTitle: 'Type opdracht',
      headDescription: 'Geef aan naar wat voor type schilder je op zoek bent.',
      path: '/offertes-aanvragen/type-opdracht',
      progressValue: 20,
      checkoutStep: 1,
    }
  },
}
</script>
