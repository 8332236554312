<template>
  <div class="service-form-part">
    <ServiceRadioField
      :choices="choices"
      v-on="$listeners"
      @result="setMarketSegmentForService($event.value[0])"
    />

    <MarketSegmentField ref="marketSegment" hidden v-on="$listeners" />
  </div>
</template>

<script>
import ServiceRadioField from 'chimera/all/components/form/fields/service/ServiceRadioField.vue'
import MarketSegmentField from 'chimera/all/components/form/fields/marketSegment/MarketSegmentField.vue'

export default {
  name: 'ServiceRadioWithMarketSegmentFormPart',

  components: {
    MarketSegmentField,
    ServiceRadioField,
  },

  props: {
    choices: {
      type: Array,
      default: null,
    },
  },

  methods: {
    /**
     * @param {object} service
     * @param {string} service.id
     */
    setMarketSegmentForService({ id }) {
      this.$refs.marketSegment.setValue(id)
    },
  },
}
</script>
