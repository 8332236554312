<template>
  <div :class="[`field-${field}`]" />
</template>

<script>
import AbstractFormField from 'chimera/all/components/form/fields/AbstractFormField'

export const field = 'market-segment'
export default {
  name: 'MarketSegmentField',

  extends: AbstractFormField,

  props: {
    field: {
      type: String,
      default: field,
    },

    isApiValidated: {
      type: Boolean,
      default: false,
    },
  },
}
</script>
