<template>
  <IndoorOrOutdoorRadioField :choices="choices" v-on="$listeners" />
</template>

<script>
import IndoorOrOutdoorRadioField from 'chimera/all/components/form/fields/indoorOrOutdoor/IndoorOrOutdoorRadioField'
import { Selectable } from 'chimera/all/components/models/Selectable'

export default {
  name: 'IndoorOrOutdoorFormPart',

  components: {
    IndoorOrOutdoorRadioField,
  },

  /**
   * @returns {object}
   */
  data() {
    return {
      choices: [
        new Selectable(this.$t('indoor'), this.$t('indoor'), this.$t('indoor')),
        new Selectable(
          this.$t('outdoor'),
          this.$t('outdoor'),
          this.$t('outdoor'),
        ),
        new Selectable(this.$t('both'), this.$t('both'), this.$t('both')),
        new Selectable(
          this.$t('unknown'),
          this.$t('unknown'),
          this.$t('unknown'),
        ),
      ],
    }
  },
}
</script>

<i18n>
{
  "nl-NL": {
    "indoor": "Binnen",
    "outdoor": "Buiten",
    "both": "Beide",
    "unknown": "Weet ik niet"
  }
}
</i18n>
