<template>
  <div class="service-form-part">
    <ServiceRadioWithMarketSegmentFormPart
      :choices="serviceTypes"
      v-on="$listeners"
    />
  </div>
</template>

<script>
import { paintingDefaultNL } from 'chimera/painting/service'
import { Selectable } from 'chimera/all/components/models/Selectable'
import ServiceRadioWithMarketSegmentFormPart from 'chimera/all/components/form/parts/serviceMarketSegment/ServiceRadioWithMarketSegmentFormPart'

export default {
  name: 'ServiceRadioFormPart',

  components: {
    ServiceRadioWithMarketSegmentFormPart,
  },

  /**
   * @returns {object}
   */
  data() {
    return {
      serviceTypes: [
        new Selectable('Particulier', 'Particulier', paintingDefaultNL.id),
        new Selectable('Zakelijk', 'Zakelijk', paintingDefaultNL.id),
      ],
    }
  },
}
</script>
