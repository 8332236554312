<template>
  <FormModal title="Gaat het om binnen- of buitenschilderwerk?" show-back-btn>
    <template #body>
      <IndoorOrOutdoorFormStep :progress-value="progressValue" />
    </template>
  </FormModal>
</template>

<script>
import FormModal from 'chimera/all/themes/blueflow/components/layout/FormModal'
import AbstractFormPage from 'chimera/all/components/page/AbstractFormPage'
import IndoorOrOutdoorFormStep from '~/components/form/steps/indoorOrOutdoor/IndoorOrOutdoorFormStep'

export default {
  components: {
    IndoorOrOutdoorFormStep,
    FormModal,
  },

  extends: AbstractFormPage,

  layout: 'form',

  /**
   * @returns {object}
   */
  data() {
    return {
      headTitle: 'Gaat het om binnen- of buitenschilderwerk?',
      headDescription: 'Geef aan waar geschilderd moet worden.',
      path: '/offertes-aanvragen/binnen-of-buiten-schilderwerk',
      progressValue: 40,
      checkoutStep: 2,
    }
  },
}
</script>
